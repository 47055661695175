.close-icon-building {
  position: absolute !important;
  left: 20rem !important;
  color: #64748b !important;
  margin-top: 20px !important;
}
.model-building-text {
  margin-top: -20px !important;
  margin-left: -12rem !important;
  margin-bottom: 5px !important;
  font-size: var(--font-size) !important;
  color: #000000 !important;
  font-family: var(--font-family) !important;
}
.input-style {
  width: 18rem !important;
}
