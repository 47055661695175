.add-course-button {
  background-color: var(--common-color) !important;
  opacity: 100% !important;
  margin-bottom: 1.5rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-left: 30px !important;
  margin-top: 12px !important;
}

.add-course-button-disabled {
  background-color: #aaacae !important;
  opacity: 100% !important;
  margin-bottom: 1.5rem !important;
  width: 1.5rem !important;
  margin-left: 30px !important;
  margin-top: 12px !important;
}

.add-course-button:hover {
  background-color: var(--common-color) !important;
  opacity: 50% !important;
}

.course-content-body {
  display: flex !important;
  width: fit-content !important;
  height: 100% !important;
  position: relative !important;
  font-family: var(--font-family) !important;
  align-items: center !important;
  margin-left: 30px !important;
}

.course-search-box {
  position: relative !important;
  width: 269px !important;
  height: 45px !important;
}

.css-h8lxwn-MuiListItem-root {
  padding-left: 1rem !important;
}

.course-text {
  color: var(--common-color) !important;
  font-size: 1.1rem !important;
  margin-top: 12px !important;
  margin-left: 10px !important;
}

.total-course-style {
  margin-left: 5rem !important;
}

.total-course-count {
  font-size: 1.1rem !important;
  margin-top: 12px !important;
  margin-left: 10px !important;
}

.courses-tab-paper-padding {
  height: 100% !important;
  min-height: 70vh;
  max-width: 190%;
  width: 190%;
  margin-top: 10px;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-left: 7px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.courses-list-margin {
  margin-left: 290px !important;
}

.courses-list-style {
  width: 100% !important;
}

@media screen and (min-width: 1950px) {
  .courses-list-style {
    width: 55% !important;
  }
}
