.close-icon-class{
    position: absolute !important;
    left: 20rem !important;
    top: 0.8rem !important;
    color: #64748B !important;
}
.department-text1{
    margin-top: -20px !important;
    margin-left: -10.5rem !important;
    margin-bottom: 5px  !important;
}

.radio-btn{
    margin-top: -2.3rem !important;
    margin-left: 11rem !important;
    margin-bottom: 1rem  !important;
}
.save-class-button{
    margin-left: 23px !important;
    margin-right: 20px !important;
    margin-bottom: 20px !important;
    width: 18rem !important;
    background-color: var(--common-color) !important;
    color: #ffffff !important;
    font-family: var(--font-family) !important;
}
.cancel-class-button{
    margin-left: 30px !important;
    margin-right: 20px !important;
    margin-bottom: 20px !important;
    width: 18rem !important;
    color: var(--common-color) !important;
    border: 2px solid var(--common-color);
    background-color: transparent;
    font-family: var(--font-family) !important;
}