/* =====================================|| HOME PAGE CSS ||====================================== */

.semester-table-cell-item-text {
  padding: 20px !important;
  font-size: var(--font-size);
  border-left: none;
  text-align: left !important;
  border-bottom: none !important;
}

.add-new-semester-button {
  margin-left: 87% !important;
  background-color: var(--common-color) !important;
  color: white !important;
  width: 13% !important;
  padding: 9px !important;
}

.semester-save-button {
  margin: 0 20px 20px 23px !important;
  width: 7rem !important;
  color: #ffffff !important;
  font-family: var(--font-family) !important;
}

.semester-save-button-background {
  background-color: var(--common-color) !important;
}

.semester-save-button-disabled-background {
  background-color: #aaacae !important;
}

.semester-cancel-button {
  margin: 0 20px 20px 30px !important;
  width: 7rem !important;
  color: var(--common-color) !important;
  border-color: var(--common-color) !important;
  background-color: transparent;
  font-family: var(--font-family) !important;
}

.add-semester-labels {
  font-size: var(--font-size) !important;
  margin-bottom: 1% !important;
  font-family: var(--font-family) !important;
}

.semester-modal-close-icon {
  position: absolute !important;
  left: 18rem !important;
  top: 1.2rem !important;
  color: #64748b !important;
}

.semester-type-style {
  font-family: var(--font-family) !important;
}

.semester-modal-radio-group-style {
  display: flex !important;
  flex-direction: row !important;
}

.add-new-semester-error {
  font-family: var(--font-family) !important;
  color: red !important;
  font-size: var(--font-size) !important;
  margin-top: 0.5rem !important;
  max-width: 250px;
  margin-left: 10%;
}

.semester-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 20px;
  margin-top: 100px !important;
}

.semester-table-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid #aaacae;
}

.semester-table-head-title {
  padding: 20px !important;
  border-right: 1px solid #aaacae;
  border-bottom: 1px solid #aaacae;
  background: #aaacae;
  color: white;
  font: var(--font-family);
  font-size: 1rem !important;
  text-align: left !important;
}

.semester-table-head-title:last-child {
  border-right: none; /* Remove border-right for the last column */
}

.semester-table-box {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  background: white;
  min-width: max-content !important;
}

@media screen and (min-width: 750px) {
  .semester-table-box {
    min-width: auto !important;
  }
}

.semester-content-text {
  padding: 20px;
  font: var(--font-family);
  color: #000000;
}

.semester-table-height {
  overflow: hidden !important;
  max-height: calc(85vh - 8rem) !important;
}

.semester-table-height:hover {
  overflow-y: auto !important;
}

.semester-table-header-sticky {
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}
