/* =========================== DATA ANALYSIS CSS ================================ */

.data-analysis-modal-close-icon {
  width: 2rem !important;
  padding: 0 !important;
  border-radius: 50% !important;
  background-color: white !important;
  color: #000;
}

.data-analysis-modal-width {
  max-width: 80% !important;
}

.data-analysis-modal-header-margin {
  margin-bottom: 10px !important;
}

.data-analysis-modal-content-height {
  min-height: 95% !important;
}

.data-analysis-lhs-list {
  position: relative !important;
  width: 222px !important;
  padding: 20px 10px 10px 0px !important;
  background: #fff !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.data-analysis-lhs-list-option-text {
  font-size: 14px !important;
  color: #000 !important;
  font-family: var(--font-family) !important;
  text-transform: none !important;
}

.data-analysis-lhs-list-option {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;
  gap: 10px !important;
  align-self: stretch !important;
}

.data-analysis-lhs-list-button {
  display: flex !important;
  padding: 10px !important;
  align-items: flex-start !important;
  gap: 10px !important;
  align-self: stretch !important;
  border-radius: 5px !important;
  background: #fff !important;
  justify-content: flex-start !important;
  overflow: visible !important;
  white-space: normal !important;
  flex-wrap: wrap !important;
  text-align: left !important;
}

.data-analysis-lhs-list-button:hover {
  cursor: pointer !important;
}

.data-analysis-lhs-list-button.selected {
  background: #e4e7ee !important;
}

.data-analysis-content {
  flex: 1 !important;
}

.data-analysis-modal-rhs-content-scroll {
  max-height: 60vh !important;
  background-color: white !important;
  position: relative !important;
  overflow-y: auto;
}

.data-analysis-modal-rhs-content-scroll::-webkit-scrollbar {
  width: 5px !important;
}

@media screen and (max-width: 1290px) {
  .data-analysis-modal-rhs-content-scroll {
    max-height: 40vh !important;
  }
}

@media screen and (max-width: 980px) {
  .data-analysis-modal-rhs-content-scroll {
    max-height: 20vh !important;
  }
}

.data-analysis-modal-rhs-content {
  background-color: var(--color-gray-15) !important;
  position: relative !important;
  align-items: center !important;
  padding: 20px !important;
}

.data-analysis-dropdown-button {
  text-transform: none !important;
  justify-content: flex-start !important;
  padding-left: 27px !important;
}

.data-analysis-filter-part {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 20px !important;
}

.data-analysis-dropdown-gap {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.data-analysis-info-gap {
  gap: 50px !important;
}

.data-analysis-rhs-info-text {
  font: var(--font-family);
  font-size: 14px;
  color: var(--common-color);
}

.data-analysis-rhs-info-value {
  color: #000 !important;
}

.infrastructure-utilization-table-cell {
  font-weight: bold !important;
  font-size: 14px !important;
  border: 1px solid #000 !important;
  padding: 8px !important;
}

.infrastructure-utilization-table-cell-count {
  border: none !important;
  padding: 0 !important;
}

.infrastructure-utilization-table-item {
  font-weight: normal !important;
  text-align: left !important;
}

.infrastructure-utilization-table-row-highlight {
  background-color: #ffb6c180;
}

.data-analysis-rhs-data-not-found {
  font: var(--font-family);
  font-size: 16px;
  color: #d32f2f;
}

.ideal-teaching-hours {
  font: var(--font-family);
  font-size: 16px;
  color: #000;
  margin-left: auto !important;
}

.ideal-teaching-hours-icon {
  width: 20px !important;
  height: 20px !important;
  color: #ef7918;
}

.data-analysis-filter-dropdown-clear {
  position: absolute !important;
  right: 30px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  z-index: 10 !important;
}

.data-analysis-rhs-loader {
  display: flex;
  justify-content: center;
  width: 80%;
  align-items: center;
}

@media screen and (max-width: 1400px) {
  .data-analysis-rhs-loader {
    width: 70% !important;
  }
}

.selected-menu-item {
  background-color: rgba(25, 118, 210, 0.08) !important;
}

.hover-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.analysis-modal-dropdown-items {
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.analysis-modal-chip-text {
  max-width: 180px !important;
}
