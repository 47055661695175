.staff-content--button:hover .course-menu-icon {
  visibility: visible;
}
.staff-content--button--selected {
  background: #e5e5e5 !important;
  border-radius: 0px;
}
.staff-content--button {
  font: 500 14px "sans-serif";
  color: #454e64;
  width: 100% !important;
  height: 50px;
  font-size: var(--font-size) !important;
  color: black !important;
}

.staff-details-header {
  color: var(--common-color) !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  margin-left: -3rem !important;
  width: 120px !important;
}

.staff-details-text {
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  margin-left: 1rem !important;
}
.staff-text1 {
  margin-top: -7rem !important;
}
.staff-text2 {
  margin-top: -5rem !important;
}
.staff-text3 {
  margin-top: -3rem !important;
}
.staff-text4 {
  margin-top: -1rem !important;
}

.add-staff-button {
  background-color: var(--common-color) !important;
  opacity: 100% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-left: 10px !important;
}

.add-staff-button-disabled {
  background-color: #aaacae !important;
  opacity: 100% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-left: 10px !important;
}

.add-staff-button:hover {
  background-color: var(--common-color) !important;
  opacity: 50% !important;
}

.add-staff-course-button {
  background-color: var(--common-color) !important;
  opacity: 100% !important;
  margin-bottom: 1.5rem !important;
  margin-top: 1rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-left: 20px !important;
}

.add-staff-course-button-disabled {
  background-color: #aaacae !important;
  opacity: 100% !important;
  margin-bottom: 1.5rem !important;
  margin-top: 1rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-left: 20px !important;
}

.add-staff-course-button:hover {
  background-color: var(--common-color) !important;
  opacity: 50% !important;
}
.staff-menu-icon {
  visibility: hidden;
  background-color: transparent;
  border: none;
  left: 12% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.staff-content--button:hover .staff-menu-icon {
  visibility: visible;
}

.stafflist {
  text-align: left !important;
  height: 3rem !important;
  width: 100% !important;
}

.staff-text {
  color: var(--common-color) !important;
  margin-left: 10px !important;
}

.css-1hfbu6x-MuiList-root {
  background-color: transparent !important;
}
.css-118i4c8-MuiList-root {
  background-color: transparent !important;
}
.css-buk6en-MuiList-root {
  background-color: transparent !important;
}
.list-title-sort {
  align-items: center;
}
.staff-header {
  font-family: var(--font-family) !important;
  color: #000;
  font-weight: bold !important;
}
.staff-page {
  font-family: var(--font-family) !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.staff-list {
  width: 100% !important;
}

.activate-deactivate-dots {
  display: flex;
  justify-content: space-between;
  padding-right: 1.5rem;
  align-items: center;
}
.class-dropdown {
  margin-top: -6px !important;
  width: 18.7rem !important;
  height: 2.5rem !important;
  text-align: left !important;
}
.staff-assignment-text1 {
  margin-left: -13.5rem !important;
  margin-bottom: 0.5rem !important;
}
.staff-assignment-text2 {
  margin-left: -10rem !important;
  margin-bottom: 0.6rem !important;
}
.staff-assignment-text3 {
  margin-left: -5.5rem !important;
  margin-bottom: 0.6rem !important;
}
.staff-assignment-text4 {
  margin-left: -4.2rem !important;
  margin-bottom: 0.6rem !important;
}
.edit-text1 {
  margin-top: 0.5rem !important;
  margin-left: -12rem !important;
}
.staff-grid {
  margin-top: -1rem !important;
  margin-left: 0rem !important;
}

.staff-list-height {
  overflow: hidden !important;
  max-height: calc(85vh - 14.5rem) !important;
  padding-right: 5px !important;
}

@media screen and (min-width: 1800px) {
  .staff-list-height {
    max-height: calc(85vh - 16.5rem) !important;
  }
}

.staff-list-height:hover {
  overflow-y: auto !important;
  padding-right: 0px !important;
}

.staff-department-text {
  margin-left: 5% !important;
}
.staff-timePreference {
  width: 17.8rem !important;
  margin-left: 0.5rem !important;
}
.staff-doneIcon {
  width: 1.3rem !important;
  height: 1.3rem !important;
  margin-left: 8.7rem !important;
}
.staffContent-grid {
  margin-top: 8rem !important;
}

.staff-list-style {
  width: 100% !important;
  max-width: 90vh !important;
  max-height: 60vh !important;
  margin-top: 3% !important;
}

.staff-TabScroller {
  max-height: 530px !important;
  overflow-y: auto !important;
}

.staff-list-divider {
  width: 100% !important;
}

.staff-list-text-style {
  text-align: left !important;
  flex: 1 !important;
  font-size: var(--font-size) !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  display: block !important;
}

.staff-rhs-width {
  width: 100% !important;
}

@media screen and (min-width: 1950px) {
  .staff-rhs-width {
    width: 65% !important;
    margin-left: 10px !important;
  }
}

.staff-rhs-content {
  overflow: hidden !important;
  max-height: calc(85vh - 8rem) !important;
}

.staff-rhs-content:hover {
  overflow-y: auto !important;
}

.staff-rhs-info-margin {
  margin-left: 70px !important;
}

.staff-course-assignment-list {
  width: 100% !important;
}

.staff-content-paper-padding {
  min-height: 70vh !important;
  max-width: 190% !important;
  width: 190% !important;
  margin-top: 10px !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  padding-left: 7px !important;
  padding-right: 20px !important;
  padding-top: 30px !important;
  padding-bottom: 20px !important;
  height: 100% !important;
}

.staff-content-colon-style {
  color: var(--common-color) !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
}

.staff-day-preference {
  width: 18.7rem !important;
}
