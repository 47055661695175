.close-icon-class {
  position: absolute !important;
  left: 20rem !important;
  margin-top: 8px !important;
  color: #64748b !important;
}
.close-icon-course-assignment {
  position: absolute !important;
  left: 27rem !important;
  top: 1.4rem !important;
  color: #64748b !important;
}
.course-assignment-text1 {
  margin-top: 1rem !important;
  margin-left: -10rem !important;
  margin-bottom: 5px !important;
}
.course-assignment-text2 {
  margin-top: 1rem !important;
  margin-left: -12rem !important;
  margin-bottom: 5px !important;
}
.course-assignment-text3 {
  margin-top: 1rem !important;
  margin-left: -11.3rem !important;
  margin-bottom: 5px !important;
}
.course-assignment-text {
  margin-top: 1rem !important;
  margin-left: -6.9rem !important;
  margin-bottom: 5px !important;
}
.course-assignment-text4 {
  margin-top: 1rem !important;
  margin-left: -6.5rem !important;
  margin-bottom: 5px !important;
}
.radio-btn {
  margin-top: -2.3rem !important;
  margin-left: 11rem !important;
  margin-bottom: 1rem !important;
}
.save-class-button {
  margin-left: 23px !important;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
  width: 18rem !important;
  background-color: var(--common-color) !important;
  color: #ffffff !important;
  font-family: var(--font-family) !important;
}
.cancel-class-button {
  margin-left: 30px !important;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
  width: 18rem !important;
  color: var(--common-color) !important;
  border: 2px solid var(--common-color);
  background-color: transparent;
  font-family: var(--font-family) !important;
}
.department-dropdown {
  left: 0rem !important;
  margin-top: -6px !important;
  width: 18rem !important;
  text-align: left !important;
}
.possible-rooms-dropdown {
  left: 0.35rem !important;
  margin-top: -6px !important;
  width: 18rem !important;
  height: 2.5rem !important;
  text-align: left !important;
}
.class-text1 {
  margin-top: -1rem !important;
  margin-left: -13rem !important;
  margin-bottom: 5px !important;
}
.class-text2 {
  margin-left: -9.8rem !important;
}
.class-course-error {
  font-family: var(--font-family) !important;
  color: red !important;
  font-size: var(--font-size) !important;
  width: 400px;
  margin-left: 5%;
  text-align: left;
}

.possible-rooms-dropdown-text-align {
  text-align: left !important;
}

.possible-rooms-dropdown-list {
  pointer-events: none !important;
  position: relative !important;
}

.possible-rooms-dropdown-option-icon {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}
