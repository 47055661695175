/* ====================================== LOGIN CSS =========================================== */

.login {
  position: absolute;
  top: 35%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
}

.campusplanner-logo-login {
  height: 120px;
}

.login-submit {
  margin-left: 27% !important;
  width: 50% !important;
  background-color: var(--common-color) !important;
}

.error-text {
  font-family: var(--font-family) !important;
  color: var(--k-error-red) !important;
  font-size: var(--font-size) !important;
}

.username-textbox {
  width: 300px !important;
  height: 40px !important;
  margin-top: 2.5% !important;
}

.password-textbox {
  width: 300px !important;
  height: 40px !important;
  margin-top: 2.5% !important;
}

.username-text {
  font-size: var(--font-size) !important;
  margin-top: var(--1x-spacing) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 0px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.background-Login {
  background-color: var(--k-white) !important;
  height: 100vh !important;
}

.login-box {
  border-radius: var(--2x-spacing);
  margin-top: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 18%;
  width: 65%;
}

.login-action-content {
  margin-top: var(--3x-spacing);
}

/* Online CSS - For button background */
.css-1n7zltw-MuiButtonBase-root-MuiButton-root {
  background-color: var(--common-color) !important;
}
.css-bt5td0 {
  background-color: var(--common-color) !important;
}
