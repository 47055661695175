.institution-details1 {
  color: var(--common-color) !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
}
.institution-details2 {
  margin-left: 1rem !important;
  margin-bottom: 1rem !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
}

.institution-details4 {
  margin-left: 1rem !important;
  margin-bottom: 1rem !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
}
.institution-details5 {
  margin-left: 0.5rem !important;
  margin-bottom: 1rem !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  white-space: nowrap !important;
}
.add-class-course-button {
  background-color: var(--common-color) !important;
  opacity: 100% !important;
  margin-bottom: 1.5rem !important;
  margin-top: 0.5rem !important;
  margin-left: 4.4rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.add-class-course-button-disabled {
  background-color: #aaacae !important;
  opacity: 100% !important;
  margin-bottom: 1.5rem !important;
  margin-top: 0.5rem !important;
  margin-left: 4.9rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.add-class-course-button:hover {
  background-color: var(--common-color) !important;
}

.class-course-content--button-selected {
  background: #e4e7ee !important;
  border-radius: 0px;
  color: black !important;
  font-size: var(--font-size) !important;
}

.class-course-content-button {
  font: 500 14px "sans-serif";
  color: #454e64;
  height: 50px;
  color: black !important;
  font-size: var(--font-size) !important;
  width: 100% !important;
}

.class-course-content-button:hover .room-menu-icon {
  visibility: visible;
}

.class-course-list-item {
  font-size: var(--font-size) !important;
  color: black !important;
}

.class-course-list_header {
  padding: 0.5rem 0;
  margin: 0;
  border-bottom: 1px solid;
  position: sticky;
  padding-left: 0.8rem;
}

.list-title-sort {
  align-items: center;
}
.class-course-header {
  font-family: var(--font-family) !important;
  color: #000 !important;
  font-weight: bold !important;
}
.custom-chip-notcombined {
  background-color: #80daeb !important;
  opacity: 80% !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  color: #000 !important;
}
.custom-chip-iscombined {
  background-color: #bdda57 !important;
  opacity: 80% !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  color: #000 !important;
}

.legend-iscombined {
  margin-left: 1rem !important;
  background-color: #bdda57 !important;
  opacity: 80% !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  color: #000 !important;
}
.legend-notcombined {
  margin-top: 0.5rem !important;
  background-color: #80daeb !important;
  opacity: 80% !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  color: #000 !important;
}

.custom-chip-iscombined-notcombined-float {
  float: right;
}

.css-ltqf6a {
  margin-left: 0px !important;
}
.css-7l4bug {
  margin-left: -60px !important;
}
.css-q6b4x0 {
  margin-left: -50px !important;
}
.css-snjrw1 {
  margin-left: -71px !important;
}
.css-1yvh6mp {
  margin-left: -177px !important;
}
.possiblerooms-header {
  font-size: var(--font-size) !important;
  margin-left: -20% !important;
  color: var(--common-color) !important;
}

.class-course-assignment-list-style {
  margin-top: -1rem !important;
  width: 100% !important;
}

@media screen and (min-width: 1950px) {
  .class-course-assignment-list-style {
    width: 80% !important;
  }
}

.class-course-list-height {
  overflow: hidden !important;
  max-height: calc(85vh - 24.5rem) !important;
  padding-right: 5px !important;
}

.class-course-list-height:hover {
  overflow-y: auto !important;
  padding-right: 0px !important;
}

.list-width {
  width: 99% !important;
}
.hoursText-color {
  color: var(--common-color) !important;
}
.possibleRooms-hover {
  background-color: white;
  color: black;
  padding: 0.7rem;
}

.building-menu {
  color: black !important;
  font-weight: bold !important;
  font-family: var(--font-family) !important;
}
.autocomplete-classcourse {
  width: 68% !important;
  margin-bottom: 1rem !important;
  margin-top: -1rem !important;
}
.css-169yplb-MuiTypography-root {
  margin-left: 0px !important;
}

.class-rhs-content-end {
  display: flex !important;
  justify-content: flex-end !important;
}

@media screen and (min-width: 1950px) {
  .class-rhs-content-end {
    justify-content: center !important;
  }
}

.class-possible-rooms-header {
  font-size: var(--font-size) !important;
  color: var(--common-color) !important;
}

.classes-rhs-content-margin {
  margin-left: 0px !important;
}

@media screen and (min-width: 1950px) {
  .classes-rhs-content-margin {
    margin-left: 10px !important;
  }
}

.class-content-header-info-text {
  width: 80px !important;
}

.timetable-modal-label-text {
  color: var(--common-color) !important;
}

.timetable-modal-text {
  font-weight: 400 !important;
  font-size: var(--font-size) !important;
  line-height: 25px !important;
}
