body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --font-family: "IBM Plex Sans", sans-serif !important;

  --common-color: #5143ed !important;
  --k-black-fill: #000;
  --k-white: #ffffff;
  --k-error-red: #ff0000;

  --font-size: 0.875rem !important;

  --1x-spacing: 10px;
  --2x-spacing: 15px;
  --3x-spacing: 20px;
}

.MuiBackdrop-root,
.MuiModal-backdrop {
  background: transparent !important;
}
