.close-icon-staff {
  position: absolute !important;
  left: 23.5rem !important;
  margin-top: 20px !important;
  color: #64748b !important;
}
.model-staff-text {
  font-size: var(--font-size) !important;
  color: #000000 !important;
  font-family: var(--font-family) !important;
}
.add-text1 {
  margin-top: 0.5rem !important;
  margin-left: -12rem !important;
}
.add-text2 {
  margin-top: 0.5rem !important;
  margin-left: -13.5rem !important;
}
.add-text3 {
  margin-top: 0.5rem !important;
  margin-left: -10rem !important;
}
.add-text4 {
  margin-top: 0.5rem !important;
  margin-left: -8.5rem !important;
}
.add-text5 {
  margin-top: 0.5rem !important;
  margin-left: -10.5rem !important;
}
.isDummy-radio-group-style {
  display: flex !important;
  flex-direction: row !important;
  color: #000000 !important;
}
.isDummy-margin {
  margin-left: 2% !important;
}
.isDummy-margin-edit {
  margin-left: 12% !important;
}

.choose-day-preference-text {
  margin-top: 0.5rem !important;
  margin-left: -9rem !important;
}
