.room-content-button:hover .room-menu-icon {
  visibility: visible;
}

.room-list:hover .room-menu-icon {
  visibility: visible;
}

.room-menu-icon {
  visibility: hidden;
  background-color: transparent;
  border: none;
  color: #4b5563;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-left: auto !important;
}

.room-content-button-selected {
  background: #e4e7ee !important;
  color: black !important;
  font-size: var(--font-size) !important;
}

.room-content-button {
  font: 500 14px "sans-serif";
  color: #454e64;
  padding: 0px;
  height: 50px;
  color: black !important;
  font-size: var(--font-size) !important;
  width: 100% !important;
}

.room-list {
  text-align: left !important;
  margin-left: 2% !important;
  height: 3rem !important;
  width: 100% !important;
}

.room-text {
  color: var(--common-color) !important;
  font-size: 1.1rem;
  margin-left: 10px !important;
  margin-top: -24px !important;
}

.room-header1 {
  font-size: var(--font-size) !important;
  margin-left: -20% !important;
  color: black !important;
}
.course-divider {
  width: 368.5%;
  margin-top: 2.5% !important;
  margin-left: -1% !important;
  /* margin-left: -265.5% !important; */
}

.css-1lqab6c-MuiList-root {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 0;
  padding-bottom: 8px;
  margin-left: -11% !important;
  background-color: transparent !important;
}

.css-1p823my-MuiListItem-root {
  padding-bottom: 0px !important;
}

/* Online CSS - Divider */
hr.MuiDivider-root.MuiDivider-fullWidth.room-divider.css-39bbo6 {
  width: 364%;
}

.css-llj6m5-MuiList-root {
  margin: 0% !important;
}

.room-list-height {
  overflow: hidden !important;
  max-height: calc(85vh - 13rem) !important;
}

@media screen and (min-width: 1800px) {
  .room-list-height {
    max-height: calc(85vh - 16.5rem) !important;
  }
}

.room-list-height:hover {
  overflow-y: auto !important;
}
