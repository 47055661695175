/* overwritten css */

/* Tab Header Style */
.css-1c28c6c {
  min-width: 204.7% !important;
  border-radius: 0px !important;
  background-color: #f9f9f9 !important;
  margin-bottom: 0px !important;
  box-shadow: 0px 0px 0px !important;
  border: 1px solid #d0d7de !important;
  height: 3.5rem !important;
  margin-top: -0.3rem !important;
}
/* Font Style */
.css-a031h9 {
  color: #a9a9a9 !important;
  border-radius: 5rem !important;
  outline: var(--common-color) !important;
}
.css-a031h9:hover {
  color: var(--common-color) !important;
  opacity: 76% !important;
  background-color: #f9f9f9 !important;
}

.css-kqpr64 {
  color: #a9a9a9 !important;
  border-radius: 5rem !important;
  outline: var(--common-color) !important;
}
.css-kqpr64:hover {
  color: var(--common-color) !important;
  opacity: 76% !important;
  background-color: #f9f9f9 !important;
}

/* Selected Tab */
.css-a031h9.Mui-selected {
  background-color: var(--common-color) !important;
  border-radius: 5rem !important;
  color: #ffffff !important;
  outline: var(--common-color) !important;
}
.css-a031h9.Mui-selected:hover {
  color: #ffffff !important;
  opacity: 100% !important;
  background-color: var(--common-color) !important;
}

.css-kqpr64.Mui-selected {
  background-color: var(--common-color) !important;
  border-radius: 5rem !important;
  color: #ffffff !important;
  outline: var(--common-color) !important;
}
.css-kqpr64.Mui-selected:hover {
  color: #ffffff !important;
  opacity: 100% !important;
  background-color: var(--common-color) !important;
}

/* Body Content */
.css-1lxkkuj {
  width: 205% !important;
  height: 100% !important;
  border: 0px !important;
  border-radius: 0px !important;
  opacity: 1 !important;
}

.css-1fdy2tw {
  height: 0px !important;
}

.css-o63m2r {
  flex-basis: 14.1667%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 19% !important;
}

/* Common Css */
.courses {
  margin-top: 1rem !important;
  margin-left: 1rem !important;
}
.infrastructure {
  margin-top: 1rem !important;
  margin-left: 100vh !important;
  height: 80vh !important;
  width: 98vh !important;
}
.css-1633um2-MuiPaper-root {
  color: black !important;
  text-align: left !important;
}

/* Common Css */
.save-building-button {
  margin-left: 23px !important;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
  width: 18rem !important;
  background-color: var(--common-color) !important;
  color: #ffffff !important;
  font-family: var(--font-family) !important;
}
.save-building-button-disabled {
  color: #ffffff !important;
  background-color: #aaacae !important;
  border: 1px solid #aaacae !important;
  margin-left: 23px !important;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
  width: 18rem !important;
  font-family: var(--font-family) !important;
}
.cancel-building-button {
  margin-left: 30px !important;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
  width: 18rem !important;
  color: var(--common-color) !important;
  border-color: var(--common-color) !important;
  background-color: transparent;
  font-family: var(--font-family) !important;
}
.dialog-style {
  font-family: var(--font-family) !important;
  color: #1e293b;
  font-weight: 500;
}
.dialog-action {
  justify-content: center;
  padding-bottom: 20px;
}
.errorDialog-style {
  font-family: var(--font-family) !important;
  color: #1e293b;
  width: 550px;
  height: 300px;
}
.yes-btn {
  margin-top: -4rem !important;
  left: -15.5% !important;
  border: "1px solid var(--common-color)" !important;
  color: white !important;
  font-family: var(--font-family) !important;
  width: 84.25px !important;
  background-color: var(--common-color) !important;
}
.yes-btn-disabled {
  margin-top: -4rem !important;
  left: -15.5% !important;
  border: "1px solid var(--common-color)" !important;
  color: white !important;
  font-family: var(--font-family) !important;
  width: 84.25px !important;
  background-color: #aaacae !important;
}
.no-btn {
  margin-top: -4rem !important;
  left: -18% !important;
  margin-left: 1.5rem !important;
  font-family: var(--font-family) !important;
  width: 84.25px !important;
  color: var(--common-color) !important;
  border-color: var(--common-color) !important;
}
.yes-btn-delete-all {
  margin-top: -5rem !important;
  left: -22% !important;
  border: "1px solid var(--common-color)" !important;
  color: white !important;
  font-family: var(--font-family) !important;
  width: 84.25px !important;
  background-color: var(--common-color) !important;
}
.yes-btn-delete-all-disabled {
  margin-top: -5rem !important;
  left: -22% !important;
  border: "1px solid var(--common-color)" !important;
  color: white !important;
  font-family: var(--font-family) !important;
  width: 84.25px !important;
  background-color: #aaacae !important;
}
.no-btn-delete-all {
  margin-top: -5rem !important;
  left: -20% !important;
  margin-left: 1.5rem !important;
  font-family: var(--font-family) !important;
  width: 84.25px !important;
  color: var(--common-color) !important;
  border-color: var(--common-color) !important;
}
.model-close-icon {
  position: absolute !important;
  right: 1rem !important;
  margin-top: 20px !important;
}
.model-text {
  font-size: var(--font-size) !important;
  color: #000000 !important;
  font-family: var(--font-family) !important;
}
.css-ibpfkq {
  font-family: var(--font-family) !important;
  font-weight: 400 !important;
  font-size: var(--font-size) !important;
  line-height: 1.43 !important;
  letter-spacing: 0.01071em !important;
  padding: 8px !important;
  text-align: left !important;
  color: var(--common-color) !important;
}
.App {
  text-align: left !important;
}
.duplicate-text {
  font-family: var(--font-family) !important;
  color: red !important;
  font-size: var(--font-size) !important;
  margin-top: 1.5rem !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
}
.css-1633um2-MuiPaper-root {
  font-family: var(--font-family) !important;
}
/* Online CSS  */
.css-1ujsas3 {
  text-transform: none !important;
}

/* Scroller CSS */
.list-container {
  height: calc(100vh - 12.5rem);
  overflow-y: hidden;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  padding-right: 5px;
  background-color: #fff;
  border-radius: 0 0.5rem 0 0;
}
.list-combined-container {
  height: calc(85vh - 12rem);
  overflow-y: hidden;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  padding-right: 5px;
  background-color: #fff;
  border-radius: 0 0.5rem 0 0;
}
.list-container:hover {
  overflow-y: scroll;
  padding-right: 0px;
}
.list-combined-container:hover {
  overflow-y: scroll;
  padding-right: 0px;
}
/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbbfc9;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbbfc9;
}
.display-warnings {
  margin-top: 3rem !important;
  margin-left: 1.8rem !important;
}
.textOverflow-style {
  cursor: pointer !important;
  text-overflow: "ellipsis" !important;
  white-space: "nowrap" !important;
  overflow: "hidden" !important;
}
.tabHeader-style {
  width: 48.8% !important;
}
.css-tn4v5h .MuiButtonGroup-grouped:not(:last-of-type) {
  border-right: 1px solid #fff !important;
}

.dropdown-custom-height {
  max-height: 250px !important;
}

.modal-overlay-background {
  background: #2d385066 !important;
}

.show-cursor {
  cursor: pointer !important;
}
