.building-content-button-selected {
  background: #e4e7ee !important;
  color: black !important;
  font-size: var(--font-size) !important;
}

.building-content-button {
  font: 500 14px "sans-serif";
  color: #454e64;
  height: 50px;
  color: black !important;
  font-size: var(--font-size) !important;
  width: 100% !important;
  margin-left: 10px !important;
}

.building-content-button:hover .building-menu-icon {
  visibility: visible;
}

.building-menu-icon {
  visibility: hidden;
  background-color: transparent;
  border: none;
  color: #4b5563;
  left: 6% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  padding: 0px !important;
}

.css-t3w9gl-MuiList-root {
  background-color: transparent !important;
}

.name-text-color {
  color: black !important;
}

.buildings-text {
  margin-top: 30px !important;
  position: relative !important;
  color: var(--common-color) !important;
  margin-left: 10px !important;
}

.building-list-style {
  width: 100% !important;
}

@media screen and (min-width: 1950px) {
  .building-list-style {
    width: 70% !important;
  }
}

@media screen and (min-width: 1950px) {
  .infrastructure-room-area-margin {
    margin-left: -120px !important;
  }
}

.moreVertIcon-style {
  width: 1.2rem !important;
  height: 1.2rem !important;
}

.building-list {
  width: "100%";
  max-width: "100vh";
  max-height: "60vh";
  margin-top: "0.05rem";
}

.building-list-height {
  overflow: hidden !important;
  max-height: calc(85vh - 12rem) !important;
}

@media screen and (min-width: 1800px) {
  .building-list-height {
    max-height: calc(85vh - 16.5rem) !important;
  }
}

.building-list-height:hover {
  overflow-y: auto !important;
}

.infrastructure-building-header-divider {
  margin-left: 12px !important;
  width: 96% !important;
}

.infrastructure-room-header-divider {
  margin-left: 32px !important;
  width: 100% !important;
}
