.css-119rl6q {
  border-bottom: none !important;
}

.course-menu-icon {
  visibility: hidden;
  background-color: transparent;
  border: none;
  color: #4b5563;
  font-weight: 100;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-left: auto !important;
}

.course-content-button-selected {
  background: #e5e5e5 !important;
}

.course-content-button {
  font: 500 14px "sans-serif";
  color: #454e64;
  padding: 0px;
  height: 50px;
  font-size: var(--font-size) !important;
  color: black !important;
  width: 100% !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  margin: "0%" !important;
}

.course-list-height {
  overflow: hidden !important;
  max-height: calc(84vh - 15.5rem) !important;
  padding-right: 5px !important;
}

@media screen and (min-width: 1800px) {
  .course-list-height {
    max-height: calc(85vh - 16.5rem) !important;
  }
}

.course-list-height:hover {
  overflow-y: auto !important;
  padding-right: 0px !important;
}

.course-content-button:hover .course-menu-icon {
  visibility: visible;
}

.course-list {
  width: 100% !important;
}

.courses-list-text-style {
  text-align: left !important;
  flex: 1 !important;
  font-size: var(--font-size) !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  display: block !important;
}

.course-divider {
  width: 100% !important;
}
