.course-page {
  font-family: var(--font-family) !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.department-menu-icon {
  visibility: hidden;
  background-color: transparent;
  border: none;
  color: #4b5563;
  left: 8% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.department-content-button-selected {
  background: #e4e7ee !important;
  color: black !important;
  font-size: var(--font-size) !important;
}

.department-content-button {
  font: 500 14px "sans-serif";
  color: #454e64;
  height: 50px;
  color: black !important;
  font-size: var(--font-size) !important;
  width: 100% !important;
}

.department-content-button:hover .department-menu-icon {
  visibility: visible;
}

.department-text {
  color: var(--common-color) !important;
  font-size: 1.1rem;
  margin-top: 30px !important;
  margin-left: 10px !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px !important;
  padding-left: 0px !important;
}

.css-1p823my-MuiListItem-root {
  padding-left: 0px !important;
}

.css-1p823my-MuiListItem-root {
  height: 0% !important;
}

/* Online CSS - Divider */
hr.MuiDivider-root.MuiDivider-fullWidth.department-divider.css-39bbo6 {
  width: 120%;
}

.add-department-button {
  background-color: var(--common-color) !important;
  opacity: 100% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-top: 30px !important;
  margin-left: 40px !important;
}

.add-department-button-disabled {
  background-color: #aaacae !important;
  opacity: 100% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-top: 30px !important;
  margin-left: 40px !important;
}

.add-department-button:hover {
  background-color: var(--common-color) !important;
  opacity: 50% !important;
}

.css-89wwy7-MuiList-root {
  background-color: transparent !important;
}

.departments-list {
  width: 100% !important;
  max-width: 100vh !important;
  max-height: 61vh !important;
}

.department-height {
  overflow: hidden !important;
  max-height: calc(85vh - 12rem) !important;
}

@media screen and (min-width: 1800px) {
  .department-height {
    max-height: calc(85vh - 16.5rem) !important;
  }
}

.department-height:hover {
  overflow-y: auto !important;
}

.department-list {
  width: 100% !important;
}

.infrastructure-department-header-divider {
  margin-left: 25px !important;
  width: 100% !important;
}

.infrastructure-department-list-style {
  width: 100% !important;
}
