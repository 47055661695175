.close-icon-course {
  position: absolute !important;
  left: 25rem !important;
  margin-top: 20px !important;
  color: #64748b !important;
}
.course-text1 {
  margin-top: -20px !important;
  margin-left: -12.6rem !important;
  margin-bottom: 5px !important;
}
.course-text2 {
  margin-top: 1.5rem !important;
  margin-left: -10rem !important;
  margin-bottom: 5px !important;
  text-align: left !important;
  width: 8rem !important;
}
.course-radio-btn {
  margin-top: -2.3rem !important;
  margin-left: 11rem !important;
  margin-bottom: 1rem !important;
  color: black !important;
  font-size: var(--font-size) !important;
}
