/* --------------------------------- Shortcut Modal CSS ------------------------------------- */

.shortcut-modal {
  display: flex !important;
  height: 85% !important;
  width: 100% !important;
  max-width: 100% !important;
  position: absolute !important;
  justify-content: center !important;
  gap: 1.25rem !important;
  margin: auto !important;
  align-items: center !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.shortcut-modal-close-icon {
  width: 2rem !important;
  height: 2rem !important;
  padding: 0 !important;
  border-radius: 50% !important;
  background-color: white !important;
}

.shortcut-modal-content {
  padding: 20px !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 30px !important;
  border-radius: 20px !important;
  background: white !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 70% !important;
  animation: slide-up 0.3s ease-in-out !important;
}

.shortcut-modal-content-height {
  padding: 0px !important;
  height: 100% !important;
}

.shortcut-modal-content-width {
  width: 100% !important;
}

.shortcut-modal-overlay-background {
  background: #2d385066 !important;
}

.shortcut-modal-header {
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  font-weight: bold !important;
}

.shortcut-modal-header-label {
  color: var(--common-color) !important;
  width: 100px !important;
}

.shortcut-modal-header-colon {
  color: var(--common-color) !important;
  width: 10px !important;
}

.shortcut-modal-toggle-button-group {
  height: 30px !important;
  margin-bottom: 20px !important;
}

.shortcut-modal-toggle-button-text {
  color: black !important;
}

.shortcut-modal-table-body {
  width: 100% !important;
  table-layout: fixed !important;
  border-collapse: collapse !important;
}

.shortcut-modal-scroll {
  max-height: calc(80vh - 8rem) !important;
  overflow: hidden !important;
}

.shortcut-modal-scroll:hover {
  overflow-y: auto !important;
}

.shortcut-modal-text-link {
  cursor: pointer !important;
  text-decoration: underline !important;
  color: #1976d2 !important;
}

.shortcut-modal-table-cell-divider {
  border-bottom: 1px solid #ccc !important;
  margin: 8px 0 !important;
  width: 100% !important;
}

.shortcut-modal-table-sticky-header {
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}

.shortcut-modal-header-style {
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 40px !important;
}
