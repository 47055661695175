.close-icon-comclass-assignment {
  position: absolute !important;
  left: 27rem !important;
  margin-top: 20px !important;
  color: #64748b !important;
}

.class-combined-list {
  width: 100%;
  max-width: 90vh;
  max-height: 65vh;
  margin-left: 63px !important;
}

.classCombinedlist-height {
  max-height: 25vh !important;
  margin-left: 60px !important;
}

.delete-combinedclass-icon {
  width: 1.2rem !important;
  height: 1.2rem !important;
}

.class-combined-divider {
  width: 100% !important;
}
