/* ==================================== || SEMESTER SELECT WARNING || ==================================== */

.semester-select-warning-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 60%;
  margin-left: 73% !important;
  margin-top: -15% !important;
}

.semester-select-warning-text {
  font-family: var(--font-family) !important;
  color: #7f8081;
  font-size: 1rem !important;
  font-weight: bolder !important;
  display: inline;
}

.semester-select-warning-text-style {
  text-decoration: underline;
  cursor: pointer;
  color: var(--common-color);
}
