.react-calendar-timeline {
  font-family: var(--font-family) !important;
  width: 98.4% !important;
  height: auto !important;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  font-size: var(--font-size) !important;
  padding-left: 0.87rem !important;
}
.react-calendar-timeline .rct-scroll {
  overflow-x: hidden !important;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background-color: #d4d1f4 !important;
}
.react-calendar-timeline .rct-header-root {
  background-color: #d4d1f4 !important;
}
.react-calendar-timeline .rct-sidebar {
  background-color: #d4d1f4 !important;
}
.react-calendar-timeline .rct-dateHeader {
  background-color: #d4d1f4 !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: rgba(0, 0, 0, 0) !important;
}
.rct-day-1,
.rct-day-2,
.rct-day-3,
.rct-day-4,
.rct-day-5,
.rct-day-6,
.rct-day-7 {
  background: transparent !important;
}

.gantt-dialog {
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  position: absolute;
  background-color: #fdfdfd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  line-height: 25px;
  z-index: 1002;
  cursor: default;
  width: 25rem;
  height: 11rem !important;
  text-align: left;
  padding-left: 2rem !important;
  font-size: var(--font-size) !important;
  overflow-y: auto !important;
}

.fixed-group0-time-first {
  top: 10px !important;
  left: -40px !important;
}
.fixed-group0-time-four {
  top: 10px !important;
  left: -150px !important;
}
.fixed-group0-time-last {
  top: 10px !important;
  left: -250px !important;
}
.fixed-group1-time-first {
  top: 10px !important;
  left: -40px !important;
}
.fixed-group1-time-four {
  top: 10px !important;
  left: -150px !important;
}
.fixed-group1-time-last {
  top: 10px !important;
  left: -250px !important;
}

.fixed-group2-time-first {
  top: -70px !important;
  left: -40px !important;
}
.fixed-group2-time-four {
  top: -70px !important;
  left: -150px !important;
}
.fixed-group2-time-last {
  top: -70px !important;
  left: -250px !important;
}

.fixed-group3-time-first {
  top: -120px !important;
  left: -40px !important;
}
.fixed-group3-time-four {
  top: -120px !important;
  left: -150px !important;
}
.fixed-group3-time-last {
  top: -120px !important;
  left: -250px !important;
}

.fixed-group4-time-first {
  top: -150px !important;
  left: -40px !important;
}
.fixed-group4-time-last {
  top: -150px !important;
  left: -250px !important;
}

.fixed-group5-time-first {
  top: -200px !important;
  left: -40px !important;
}
.fixed-group5-time-four {
  top: -200px !important;
  left: -150px !important;
}
.fixed-group5-time-last {
  top: -200px !important;
  left: -250px !important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
  align-items: left;
}

.grid-label {
  padding: 0px;
  margin-left: -15px;
}
.grid-item {
  padding: 0px;
  margin-left: 2px;
}

.gantt-item-text {
  text-align: left !important;
  flex: 1 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  display: block !important;
  font-size: 0.8rem !important;
  line-height: 28px !important;
}

.timeline-header-sticky {
  position: sticky !important;
  top: 0 !important;
  z-index: 1000 !important;
  background-color: white !important;
  border-bottom: 1px solid #ccc !important;
}
