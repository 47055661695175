.close-icon-room{
    position: absolute !important;
    left: 23rem !important;
    margin-top: 20px !important;
    color: #64748B !important;
}
.room-text1{
    margin-top: -20px !important;
    margin-left: -13rem !important;
    margin-bottom: 5px  !important;
}
.room-text2{
    margin-top: 1.5rem !important;
    margin-left: -11.7rem !important;
    margin-bottom: 5px  !important;
}
.room-text3{
    margin-top: 1.5rem !important;
    margin-left: -10rem !important;
    margin-bottom: 5px  !important;
    width: 8rem !important;
    text-align: left !important;
}
.room-radio-btn{
    margin-top: -2.3rem !important;
    margin-left: 11rem !important;
    margin-bottom: 1rem  !important;
    color: #000000 !important;
}