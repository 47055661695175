.content-body {
  display: flex !important;
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  font-family: var(--font-family) !important;
}

.add-icon {
  color: white !important;
  position: absolute !important;
  width: 1rem !important;
  height: 1rem !important;
}

.add-icon-disabled {
  color: #aaacae !important;
  position: fixed !important;
}

/* Online CSS */
.css-1yo8bqd {
  padding: 0px !important;
}

/* Online CSS - Width*/
.css-3ytsqb {
  list-style: none;
  margin: 0px;
  padding: 0px 0px 8px;
  position: relative;
  width: 175%;
  max-width: 180vh;
  background-color: rgb(255, 255, 255);
}

.list-title-sort {
  align-items: center;
}

.dot-fixed {
  width: 10px;
  height: 10px;
  background-color: #ff9800;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2% !important;
}

.add-building-button {
  background-color: var(--common-color) !important;
  opacity: 100% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-left: 20px !important;
  margin-top: 30px !important;
}

.add-building-button-disabled {
  background-color: #aaacae !important;
  opacity: 100% !important;
  margin-left: 20px !important;
  margin-top: 30px !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.add-building-button:hover {
  background-color: var(--common-color) !important;
  opacity: 50% !important;
}

.add-room-button {
  background-color: var(--common-color) !important;
  opacity: 100% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-top: -24px !important;
  margin-left: 40px !important;
}

.add-room-button-disabled {
  background-color: #aaacae !important;
  opacity: 100% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-top: -24px !important;
  margin-left: 40px !important;
}

.add-room-button:hover {
  background-color: var(--common-color) !important;
  opacity: 50% !important;
}

.alert-error-style {
  color: #ffffff;
  margin-left: 0.5rem;
  width: 100% !important;
}

.alert-success-style {
  width: 100%;
  background-color: #ccffcc;
  margin-left: 0.5rem !important;
}

.infrastructure-paper-padding {
  height: 100% !important;
  min-height: 70vh !important;
  max-width: 190% !important;
  width: 190% !important;
  margin-right: 2rem !important;
  padding-right: 20px !important;
  padding-bottom: 20px !important;
  margin-left: 2rem !important;
  margin-top: 10px !important;
}

.infrastructure-building-list-text-style {
  text-align: left !important;
  flex: 1 !important;
  font-size: var(--font-size) !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  display: block !important;
}

.infrastructure-department-area-margin {
  margin-left: 45px !important;
}

@media screen and (min-width: 1950px) {
  .infrastructure-department-area-margin {
    margin-left: -330px !important;
  }
}
