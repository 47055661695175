.combined-text1 {
  margin-top: -2rem !important;
}
.combined-text2 {
  margin-top: 0rem !important;
}
.combined-text3 {
  margin-top: 0.7rem !important;
}

.combined-details-header {
  color: var(--common-color) !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  margin-left: 2rem !important;
  width: 240px !important;
}

.combined-details-text {
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  margin-left: 1rem !important;
}

.add-combined-class-button {
  background-color: var(--common-color) !important;
  opacity: 100% !important;
  margin-top: 6% !important;
  margin-left: 1% !important;
  margin-left: 10px !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.add-combined-class-button-disabled {
  background-color: #aaacae !important;
  opacity: 100% !important;
  margin-top: 6% !important;
  margin-left: 1% !important;
  margin-left: 10px !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.add-combined-class-button:hover {
  background-color: var(--common-color) !important;
  opacity: 50% !important;
}

.add-class-combined-button {
  background-color: var(--common-color) !important;
  opacity: 100% !important;
  margin-bottom: 1.5rem !important;
  margin-top: 0.5rem !important;
  margin-left: 2rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.add-class-combined-button:hover {
  background-color: var(--common-color) !important;
  opacity: 50% !important;
}
.add-class-combined-button-disabled {
  background-color: gray !important;
  opacity: 100% !important;
  margin-bottom: 1.5rem !important;
  margin-top: 0.5rem !important;
  margin-left: 2rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.add-class-combined-button-disabled:hover {
  background-color: gray !important;
  opacity: 50% !important;
}
.add-combined-class-icon {
  color: white !important;
  position: fixed !important;
}
.add-combined-class-icon-disabled {
  color: white !important;
  position: fixed !important;
}

.delete-all-combined-class {
  width: 1rem !important;
  height: 1rem !important;
  position: relative !important;
}

.combinedClass-details-display {
  margin-top: 3rem;
  margin-left: 2rem;
}
.display-add-delete {
  margin-left: 2rem !important;
}

.class-combined-list-style {
  margin-top: -1rem !important;
  margin-left: -8px !important;
}

.combined-class-rhs-content {
  overflow: hidden !important;
  max-height: calc(85vh - 8rem) !important;
}

.combined-class-rhs-content:hover {
  overflow-y: auto !important;
}

@media screen and (min-width: 1950px) {
  .combined-class-rhs-content {
    margin-left: 10px !important;
  }
}

.combined-class-rhs-width {
  width: 100% !important;
}

@media screen and (min-width: 1950px) {
  .combined-class-rhs-width {
    width: 65% !important;
  }
}

.combined-class-rhs-content-info-value {
  color: var(--common-color) !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
}
