/* =================================== RESULT ANALYSIS CSS ================================== */

.overall-percentage-table-cell {
  border-bottom: none !important;
  padding: 0 !important;
}

.overall-percentage-rhs-margin {
  margin-left: 70px !important;
}

.overall-percentage-header-text {
  font-family: var(--font-family) !important;
  color: #5143ed !important;
  font-size: 15px !important;
  font-weight: bold !important;
}

.overall-percentage-table-body {
  padding-top: 20px !important;
  border: none !important;
  text-align: left !important;
}

.overall-percentage-bar-label {
  font: var(--font-family) !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.overall-percentage-bar-typo-box {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overall-percentage-bar {
  width: 70px !important;
  height: 70px !important;
}

.overall-percentage-bar-box {
  position: relative !important;
  display: inline-flex !important;
}

.overall-percentage-rhs-content-scroll {
  max-height: 70vh !important;
  background-color: white !important;
  position: relative !important;
  overflow-y: auto;
}

.overall-percentage-rhs-content-scroll::-webkit-scrollbar {
  width: 5px !important;
}

.group-schedules-accordion-content-info {
  padding: 15px;
}

.group-schedules-accordion-content-table {
  padding: 30px;
  padding-top: 0;
  width: 70% !important;
}

.result-analysis-modal-rhs-content-scroll {
  max-height: 55vh !important;
  background-color: white !important;
  position: relative !important;
  overflow-y: auto;
}

.result-analysis-modal-rhs-content-scroll::-webkit-scrollbar {
  width: 5px !important;
}

@media screen and (max-width: 1400px) {
  .result-analysis-modal-rhs-content-scroll {
    max-height: 45vh !important;
  }
}

@media screen and (max-width: 2400px) {
  .result-analysis-modal-rhs-content-scroll {
    max-height: 67vh !important;
  }
}

@media screen and (max-width: 1930px) {
  .result-analysis-modal-rhs-content-scroll {
    max-height: 65vh !important;
  }
}

@media screen and (max-width: 1800px) {
  .result-analysis-modal-rhs-content-scroll {
    max-height: 55vh !important;
  }
}

@media screen and (max-width: 1400px) {
  .result-analysis-modal-rhs-content-scroll {
    max-height: 45vh !important;
  }
}

.group-schedules-table-item-border {
  border: none !important;
}

.unmatched-group-schedule-indicator {
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1% !important;
}

.group-schedules-accordion-header {
  align-items: center;
  display: flex;
  gap: 5px;
  width: 100%;
}

.group-schedules-accordion-header-name {
  font-family: var(--font-family) !important;
  font-size: 14px !important;
}

.result-analysis-subject-balancing-scroll {
  max-height: 55vh !important;
  background-color: white !important;
  position: relative !important;
  overflow-y: auto;
}

.result-analysis-subject-balancing-scroll::-webkit-scrollbar {
  width: 5px !important;
}

@media screen and (max-width: 1400px) {
  .result-analysis-subject-balancing-scroll {
    max-height: 45vh !important;
  }
}

@media screen and (max-width: 2400px) {
  .result-analysis-subject-balancing-scroll {
    max-height: 67vh !important;
  }
}

@media screen and (max-width: 1930px) {
  .result-analysis-subject-balancing-scroll {
    max-height: 65vh !important;
  }
}

@media screen and (max-width: 1800px) {
  .result-analysis-subject-balancing-scroll {
    max-height: 65vh !important;
  }
}

@media screen and (max-width: 1400px) {
  .result-analysis-subject-balancing-scroll {
    max-height: 52vh !important;
  }
}

@media screen and (max-width: 1400px) {
  .result-analysis-subject-balancing-scroll table {
    table-layout: fixed !important;
    width: 100%;
  }

  .result-analysis-subject-balancing-cell {
    white-space: normal !important;
    word-break: break-word;
    overflow-wrap: break-word;
  }
}

@media screen and (max-width: 1400px) {
  .group-schedule-accordion-scroll {
    max-height: 52vh !important;
  }
}

@media screen and (max-width: 1400px) {
  .result-analysis-subject-continuous-hours-scroll {
    max-height: 50vh !important;
  }
}
