/* ------------------------ GROUP SCHEDULE MODAL CSS -------------------------------- */

.add-modal-group-schedule-name-text {
  margin-top: -1rem !important;
  margin-left: -9rem !important;
  margin-bottom: 5px !important;
}

.modal-overlay-background {
  background: #2d385066 !important;
}

.add-modal-number-of-hours-text {
  margin-top: -1rem !important;
  margin-left: -11rem !important;
  margin-bottom: 5px !important;
}

.number-of-hours-error-text {
  margin-top: 0rem !important;
  margin-left: 0rem !important;
}
