.fixedslots-area {
  display: flex !important;
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  font-family: var(--font-family) !important;
}
.fixedslots-LHS {
  background-color: #f9f9f9 !important;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  padding-right: 5px !important;
  height: 75vh;
  min-height: 75vh;
  max-width: 23%;
  width: 23%;
}
.fixedslots-RHS {
  background-color: #f9f9f9 !important;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  height: 75vh;
  min-height: 75vh;
  max-width: 73%;
  width: 73%;
  padding: 1.25rem !important;
  display: flex !important;
  flex-direction: column !important;
}
.main-font {
  color: #a9a9a9 !important;
  font-family: var(--font-family) !important;
  font-size: 22.032px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 150px !important;
  height: 26px !important;
  top: 355px !important;
  left: 204px !important;
}
.add-fixedslot {
  font-family: var(--font-family) !important;
  width: 87.73px !important;
  background-color: var(--common-color) !important;
  border-radius: 4.15px !important;
}
.add-fixedslot-disabled {
  font-family: var(--font-family) !important;
  width: 87.73px !important;
  background-color: #aaacae !important;
  border-radius: 4.15px !important;
  color: white !important;
}

.confirm-fixedslot {
  font-family: var(--font-family) !important;
  width: 7.5rem !important;
  background-color: var(--common-color) !important;
  border-radius: 6.68px !important;
}
.confirm-fixedslot-disabled {
  font-family: var(--font-family) !important;
  width: 7.5rem !important;
  background-color: #aaacae !important;
  border: 1px solid #aaacae !important;
  color: #ffffff !important;
  border-radius: 6.68px !important;
}
.save-slot-fixedslot {
  font-family: var(--font-family) !important;
  width: 7.5rem !important;
  background-color: var(--common-color) !important;
  border-radius: 6.68px !important;
}
.save-slot-fixedslot-disabled {
  font-family: var(--font-family) !important;
  width: 7.5rem !important;
  background-color: #aaacae !important;
  border: 1px solid #aaacae !important;
  color: #ffffff !important;
  border-radius: 6.68px !important;
}
.close-icon-fixedslots {
  position: absolute !important;
  left: 95% !important;
  top: 1.3rem !important;
  color: #64748b !important;
}
.fixedslot-title-text {
  font-weight: 600 !important;
  font-size: 25.1px !important;
  line-height: 29.42px !important;
  color: #000;
}
.fixedslot-text {
  font-weight: 600 !important;
}
.fixedslot-text1 {
  margin-left: -17.5rem !important;
  margin-top: 0.5rem !important;
  font-weight: 400 !important;
}
.fixedslot-text2 {
  margin-left: -16.2rem !important;
  margin-top: 0.7rem !important;
}
.fixedslot-text3 {
  color: #7c7c7c !important;
}
.fixedslot-text-bg1 {
  background-color: #f4f4f4 !important;
  width: 18rem !important;
  text-align: left !important;
  padding: 0.3rem;
}
.fixedslot-text-bg2 {
  background-color: #f4f4f4 !important;
  width: 18rem !important;
  text-align: left !important;
  padding: 0.3rem;
}
table {
  border-collapse: collapse;
  /* width: 100%;
  max-width: 357.8px;
  height: 100%;
  min-height: 243.2px;
  margin: 0 auto;
  border-radius: 4px !important;
  font-size: 10px !important; */
}
.time-header {
  background-color: #bbbbbb !important;
  font-size: 8.5px !important;
  padding: 4.5px;
}
tr {
  border-bottom: 1px solid #d8d8d8;
}
td {
  padding: 0px !important;
  border: 1px solid #d8d8d8;
  text-align: center !important;
}
.view-header {
  background-color: #bbbbbb !important;
  font-size: 0.8rem !important;
  padding: 1rem !important;
  font-family: var(--font-family) !important;
}
.view-content {
  padding: 0.5rem !important;
}
.custom-radio-style {
  color: #35f4dd !important;
  margin-left: -0.45rem !important;
}
.custom-radio-style.selected {
  color: green !important;
  margin-left: -0.45rem !important;
}
.custom-radio-style.disabled {
  color: gray !important;
  margin-left: -0.45rem !important;
}
.fixedslot-dropdown {
  margin-top: -6px !important;
  width: 13rem !important;
  height: 2.5rem !important;
  text-align: left !important;
}
.fixedslot-dropdown-staff {
  margin-top: -6px !important;
  width: 15.8rem !important;
  height: 2.5rem !important;
  text-align: left !important;
}
.fixedslot-divider {
  width: 86% !important;
  margin-left: -6.5% !important;
  margin-top: 1% !important;
}
.model-slot-text {
  font-size: var(--font-size) !important;
  color: #000000 !important;
  font-family: var(--font-family) !important;
  text-align: left !important;
}
.edit-fixedslot-button {
  margin-left: 0% !important;
  background-color: var(--common-color) !important;
  opacity: 100% !important;
  width: 1rem !important;
  height: 1rem !important;
}
.edit-fixedslot-button:hover {
  background-color: var(--common-color) !important;
  opacity: 50% !important;
}
.day-time {
  background-color: #cff8f3 !important;
  text-align: left;
  padding: 0.5rem;
  font-size: 10px;
  color: #000;
  border-radius: 10px !important;
  font-weight: bold !important;
}
.fixedgroup-content--button--selected {
  background: #d9d9d9 !important;
  border-radius: 0px;
}
.fixedgroup-content--button {
  font: 500 14px "sans-serif";
  color: #454e64;
  width: 100% !important;
  height: 50px;
  font-size: var(--font-size) !important;
  color: black !important;
}
.fixedgroup-menu-icon {
  visibility: hidden;
  background-color: transparent;
  border: none;
  color: #4b5563;
  left: 4% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.fixedgroup-content--button:hover .fixedgroup-menu-icon {
  visibility: visible;
}
.searchfixedslot {
  width: 20% !important;
  height: 10% !important;
  margin-left: 1rem !important;
}
.even-tableRow {
  background: rgba(221, 221, 221, 0.5);
}
.odd-tableRow {
  background: rgba(255, 255, 255, 0.5);
}
.editicon-fixedslot {
  width: 0.5rem !important;
  height: 0.5rem !important;
}
.fixedSlot-stack {
  display: flex;
  justify-content: space-between;
}
.fixedslot-box-start {
  display: flex;
  justify-content: flex-start;
}
.fixedslot-box-end {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1500px) {
  .fixedslot-box-end {
    margin-left: 470px !important;
  }
}

.accordion-style {
  height: 100% !important;
  max-height: 60vh !important;
  overflow-y: auto !important;
}
.noteStyle {
  color: red !important;
}
.labelStyle {
  color: var(--common-color) !important;
}
.valueStyle {
  color: #000 !important;
}
.table-body {
  border: 1px solid #d8d8d8 !important;
}
.available-display {
  display: flex !important;
}
.handle-fixed-error {
  margin-left: 22rem !important;
  margin-top: 1.5rem !important;
}
.cellStyles {
  font-size: 1rem !important;
  height: 2rem !important;
  min-width: 10.5rem !important;
  text-align: center !important;
  vertical-align: middle !important;
}
.label-viewModel {
  font-size: 0.7rem !important;
  color: var(--common-color) !important;
}
.value-viewModel {
  font-size: 0.7rem !important;
  color: #000 !important;
  font-family: var(--font-family) !important;
}
.display-font-size {
  font-size: var(--font-size) !important;
}
.fixedslot-list-height {
  max-height: 60vh !important;
  background-color: #f9f9f9 !important;
}
.fixedSlot-list-style {
  width: 100% !important;
  max-width: 100vh !important;
  background-color: #f9f9f9 !important;
  max-height: 60vh !important;
}
.fixedslot-stafflist {
  width: 140% !important;
  max-width: 140vh !important;
  position: relative !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
  max-height: 60vh !important;
}

.fixed-slot-rhs-width {
  width: 100% !important;
}

@media screen and (min-width: 1950px) {
  .fixed-slot-rhs-width {
    width: 85% !important;
  }
}
