.list-title-sort {
  align-items: center;
}
.staff-course-header {
  font-family: var(--font-family) !important;
  color: #000;
  font-weight: bold !important;
}
.staffcourse-content--button--selected {
  background: #e5e5e5 !important;
  border-radius: 0px;
}

.staff-course-content-button-selected {
  background: #e4e7ee !important;
  border-radius: 0px;
  color: black !important;
  font-size: var(--font-size) !important;
}

.staff-course-content-button {
  font: 500 14px "sans-serif";
  color: #454e64;
  height: 50px;
  color: black !important;
  font-size: var(--font-size) !important;
  width: 100% !important;
}

.staff-course-content-button:hover .room-menu-icon {
  visibility: visible;
}

.staff-course-list-margin {
  margin-left: 10px !important;
}

.staff-course-list-height {
  max-height: calc(85vh - 27rem) !important;
  overflow: hidden !important;
}

.staff-course-list-height:hover {
  overflow-y: auto !important;
}

.staff-course-divider {
  color: gray;
  width: 99% !important;
}

.staff-course-list-style {
  width: 100% !important;
  max-height: 65vh !important;
  margin-left: 10px !important;
}

.staff-course-assignment-possible-rooms {
  font-size: var(--font-size) !important;
  color: var(--common-color) !important;
  font-family: var(--font-family) !important;
}

.staff-course-assignment-list-item-text {
  text-align: left !important;
  flex: 1 !important;
  font-size: var(--font-size) !important;
  font-family: var(--font-family) !important;
}
