/* ------------------------------- GROUP SCHEDULE CSS ----------------------------- */

.group-schedule-content-header-info-text {
  width: 150px !important;
}

.group-schedule-course-content-divider {
  color: gray;
  width: 100% !important;
}

.group-schedule-course-list-height {
  max-height: calc(85vh - 20rem) !important;
  overflow: hidden !important;
  padding-right: 5px !important;
}

.group-schedule-course-list-height:hover {
  overflow-y: auto !important;
  padding-right: 0px !important;
}
