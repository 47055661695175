.import-warning {
  margin-top: -3rem !important;
  margin-left: -2rem !important;
  padding-bottom: 0px !important;
  padding-top: 5px !important;
  width: 210% !important;
}
.import-warning__alert-title {
  margin-top: -2px !important;
  margin-left: 0px !important;
}
.data-analysis-warning {
  margin-top: 1px !important;
}
.view_icon {
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  right: 18% !important;
  color: #1e293b !important;
}
.view_icon_failed {
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  right: 7% !important;
  color: #1e293b !important;
}
.view_title {
  color: rgb(102, 60, 0) !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 24px !important;
}
.download_icon {
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  right: 10.2% !important;
  color: #1e293b !important;
}
.download_title {
  color: rgb(102, 60, 0) !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 24px !important;
}
.errorDialog-style {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  color: #1e293b !important;
}
.timetable {
  background-color: #f9f9f9 !important;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  height: 82.5vh;
  min-height: 79vh;
  width: 202.7% !important;
  padding: 1.25rem !important;
  display: flex !important;
  flex-direction: column !important;
}
.searchTimetable {
  width: 190px !important;
  height: 40px !important;
  margin-left: 2rem !important;
  margin-top: 0.3rem !important;
  margin-right: 10px !important;
}
.generate-timetable {
  font-family: var(--font-family) !important;
  width: 10.2rem !important;
  border-radius: 4.15px !important;
  color: #000 !important;
  text-transform: none !important;
  background-color: #35f4dd !important;
}
.generate-timetable-disabled {
  font-family: var(--font-family) !important;
  width: 10.2rem !important;
  background-color: #aaacae !important;
  border-radius: 4.15px !important;
  color: #000 !important;
  text-transform: none !important;
}
.algorithm-tryagain {
  font-family: var(--font-family) !important;
  width: 10.2rem !important;
  border-radius: 4.15px !important;
  color: #000 !important;
  text-transform: none !important;
}
.algorithm-running {
  font-family: var(--font-family) !important;
  width: 10.2rem !important;
  border-radius: 4.15px !important;
  color: #000 !important;
  background-color: #aaacae !important;
  text-transform: none !important;
}
.algorithm-completed {
  font-family: var(--font-family) !important;
  width: 10.2rem !important;
  border-radius: 4.15px !important;
  color: #000 !important;
  text-transform: none !important;
  background-color: #ffa07a !important;
}
.algorithm-completed-disabled {
  font-family: var(--font-family) !important;
  width: 10.2rem !important;
  background-color: #aaacae !important;
  border-radius: 4.15px !important;
  color: #000 !important;
  text-transform: none !important;
}
.algorithm-button__loader {
  padding-left: 5px;
  width: 30px;
  height: 30px;
}
.pdf-button {
  font-family: var(--font-family) !important;
  border-radius: 4.15px !important;
  color: #fff !important;
  text-transform: none !important;
}
.pdf-width1 {
  text-transform: none !important;
  width: 12.5rem !important;
  background-color: #66b032 !important;
}
.pdf-width2 {
  text-transform: none !important;
  width: 12.5rem !important;
  background-color: #66b032 !important;
}
.css-zqcytf-MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid #bdbdbd;
  border-color: #bdbdbd !important;
}
.group0-time-first {
  top: 10px !important;
  left: -40px !important;
}
.group0-time-last {
  top: 10px !important;
  left: -150px !important;
}

.group1-time-first {
  top: -30px !important;
  left: -40px !important;
}
.group1-time-last {
  top: -30px !important;
  left: -150px !important;
}

.group2-time-first {
  top: -70px !important;
  left: -40px !important;
}
.group2-time-last {
  top: -70px !important;
  left: -150px !important;
}

.group3-time-first {
  top: -120px !important;
  left: -40px !important;
}
.group3-time-last {
  top: -120px !important;
  left: -150px !important;
}

.group4-time-first {
  top: -150px !important;
  left: -40px !important;
}
.group4-time-last {
  top: -150px !important;
  left: -150px !important;
}

.group5-time-first {
  top: -200px !important;
  left: -40px !important;
}
.group5-time-last {
  top: -200px !important;
  left: -150px !important;
}
.disable-accordion {
  background-color: #fff !important;
}
/* Online CSS  -  For Hide/Show Icon*/
.css-1yxmbwk {
  padding: 0.5px !important;
}
.visibilityIcon-color {
  color: #1e293b !important;
  padding-right: 5px !important;
}
.customTransform {
  transform-origin: center top;
}
.customTransformBottom {
  transform-origin: center bottom;
}

.accordion-timeTable {
  height: 100% !important;
  max-height: 53vh !important;
  overflow-y: auto !important;
  width: 100% !important;
}

.timetable-content-header {
  width: 100% !important;
}

@media screen and (min-width: 1950px) {
  .timetable-content-header {
    width: 85% !important;
  }
}

.department-dropdown-margin {
  margin-top: 0.35rem !important;
}
.view-download-icon {
  width: 1.5rem !important;
  height: 1.5rem !important;
  color: #fff !important;
}
.departmentpdf-download-button-disabled {
  background-color: #aaacae !important;
  opacity: 100% !important;
  width: 2rem !important;
  height: 2rem !important;
  margin-top: -10px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.departmentpdf-download-button {
  opacity: 100% !important;
  width: 2rem !important;
  height: 2rem !important;
  background-color: var(--common-color) !important;
  margin-top: -10px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.download-pdf-button {
  background-color: #66b032 !important;
}
.viewCount-iconButton {
  width: 2rem !important;
  height: 2rem !important;
  background-color: var(--common-color) !important;
  margin-top: 0.3rem !important;
}
.warnings-view-icon {
  color: #1e293b !important;
}
.overAll-count-text {
  text-align: left !important;
  line-height: 2rem !important;
  color: #000 !important;
}

.timetable-rhs-buttons-margin {
  margin-left: 10px !important;
}

.timetable-rhs-buttons-margin-right {
  margin-right: 10px !important;
}

.timetable-tabs-style {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
}

.alert-icon-button-border {
  border-radius: 5% !important;
}
