.cc-paper-padding {
  height: 70vh;
  min-height: 70vh;
  max-width: 190%;
  width: 190%;
  margin-top: 10px;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-left: 7px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.combined-class-text {
  color: var(--common-color) !important;
  margin-top: 10px !important;
  margin-left: 10px !important;
}

.close-icon-comclass {
  position: absolute !important;
  left: 20rem !important;
  top: 1.3rem !important;
  color: #64748b !important;
}
.comclass-text2 {
  margin-left: -8.6rem !important;
}
.comcls-text {
  margin-left: -14.9rem !important;
  margin-bottom: 0.2rem !important;
}

.class-combined-list-button-selected {
  background: #e5e5e5 !important;
  border-radius: 0px;
}

.class-combined-list-button {
  font: 500 14px "sans-serif";
  color: #454e64;
  padding: 0px;
  height: 50px;
  font-size: var(--font-size) !important;
  color: black !important;
  width: 100% !important;
}

.combinedclass-menu-icon {
  visibility: hidden;
  background-color: transparent;
  border: none;
  color: #4b5563;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto !important;
}

.comclass-list {
  text-align: left !important;
  height: 3rem !important;
  width: 100% !important;
}

.class-combined-list-button:hover .combinedclass-menu-icon {
  visibility: visible;
}

.list-title-sort {
  align-items: center;
}
.comclass-list_header {
  padding: 0.5rem 0;
  margin: 0;
  border-bottom: 1px solid #e8eaed;
  position: sticky;
  padding-left: 0.8rem;
}
.comclass-list_header-Grid {
  margin-left: 5px;
  text-align: left;
  align-items: center;
  justify-content: space-around;
  margin: 0;
}
.activate-deactivate-dots {
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
}
.comclass-header {
  font-weight: bold !important;
  color: #000;
  font-size: var(--font-size) !important;
}

.combined-class-divider {
  width: 100% !important;
}

.combinedclass-dropdown {
  margin-top: -6px !important;
  margin-bottom: 0.7rem !important;
  width: 21rem !important;
  height: 2.5rem !important;
  text-align: left !important;
}
.close-icon-class-class {
  position: absolute !important;
  left: 24rem !important;
  top: 1.2rem !important;
  color: #64748b !important;
}
/* Combined Class List - LHS */
.css-1jew8eu-MuiGrid-root {
  margin-left: 10px !important;
}

.combined-class-list-text-style {
  text-align: left !important;
  flex: 1 !important;
  font-size: var(--font-size) !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  display: block !important;
}

.handle-error {
  margin-left: -1rem !important;
  margin-top: 0rem !important;
}

.combinedclass-list {
  width: 100% !important;
}

.combined-class-list-style {
  width: 100%;
  max-width: 90vh;
  max-height: 65vh;
  margin-left: 0%;
  margin-top: 1.2rem;
}

.combined-class-list-height {
  overflow: hidden !important;
  max-height: calc(85vh - 13rem) !important;
  padding-right: 5px !important;
}

@media screen and (min-width: 1800px) {
  .combined-class-list-height {
    max-height: calc(85vh - 16.5rem) !important;
  }
}

.combined-class-list-height:hover {
  overflow-y: auto !important;
  padding-right: 0px !important;
}

.combined-class-add-button {
  background-color: var(--common-color) !important;
  opacity: 100% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-top: 10px !important;
  margin-left: 10px !important;
}

.combined-class-add-button-disabled {
  background-color: #aaacae !important;
  opacity: 100% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-top: 10px !important;
  margin-left: 10px !important;
}

.combined-class-add-button:hover {
  background-color: var(--common-color) !important;
  opacity: 50% !important;
}

.combined-class-list {
  text-align: left !important;
  height: 3rem !important;
  width: 100% !important;
}

.combined-class-list:hover {
  visibility: visible;
}

.combined-class-list-button-selected {
  background: #e5e5e5 !important;
  border-radius: 0px;
}

.combined-class-list-button {
  font: 500 14px "sans-serif";
  color: #454e64;
  padding: 0px;
  height: 50px;
  font-size: var(--font-size) !important;
  color: black !important;
  width: 100% !important;
}

.combined-class-menu-icon {
  visibility: hidden;
  background-color: transparent;
  border: none;
  color: #4b5563;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto !important;
}

.combined-class-list-button:hover .combined-class-menu-icon {
  visibility: visible;
}
