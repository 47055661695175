.course-page {
  font-family: var(--font-family) !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.class-content--button--selected {
  background: #e4e7ee !important;
  border-radius: 0px;
  color: black !important;
  font-size: var(--font-size) !important;
}
.class-content--button {
  font: 500 14px "sans-serif";
  color: #454e64;
  height: 50px;
  color: black !important;
  font-size: var(--font-size) !important;
  width: 100% !important;
}
.paper-padding {
  height: 70vh;
  min-height: 70vh;
  max-width: 190%;
  width: 190%;
  margin-top: 10px;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-left: 7px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.add-class-button {
  background-color: var(--common-color) !important;
  opacity: 100% !important;
  margin-left: 10px !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.add-class-button-disabled {
  background-color: #aaacae !important;
  opacity: 100% !important;
  margin-left: 10px !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.add-class-button:hover {
  background-color: var(--common-color) !important;
  opacity: 50% !important;
}

.classlist {
  margin-top: 4% !important;
  width: 100% !important;
}

.class-text {
  margin-left: 10px !important;
  color: var(--common-color) !important;
}

.css-7pkymv-MuiList-root {
  background-color: transparent !important;
}
.list-title-sort {
  align-items: center;
}
.class-menu-icon {
  visibility: hidden;
  background-color: transparent;
  border: none;
  color: #4b5563;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 15% !important;
}

.departmentwise-divider {
  margin-left: 10px !important;
  color: gray !important;
}

.class-grid {
  margin-top: -1rem !important;
  margin-left: 1.1rem !important;
}
.classlist-style {
  width: 90%;
  max-width: 90vh;
  max-height: 65vh;
  margin-left: 0%;
  margin-top: 1.2rem;
}

.classlist-height {
  overflow: hidden !important;
  max-height: calc(85vh - 13rem) !important;
  padding-right: 5px !important;
}

@media screen and (min-width: 1800px) {
  .classlist-height {
    max-height: calc(85vh - 16.5rem) !important;
  }
}

.classlist-height:hover {
  overflow-y: auto !important;
  padding-right: 0px !important;
}

.class-list {
  text-align: left !important;
  margin-left: 2% !important;
  height: 3rem !important;
  width: 100% !important;
}
.class-content--button:hover .class-menu-icon {
  visibility: visible;
}
.class-TabScroller {
  max-height: 510px !important;
  overflow-y: auto !important;
}

.class-list-text-style {
  text-align: left !important;
  flex: 1 !important;
  font-size: var(--font-size) !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  display: block !important;
}
