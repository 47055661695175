/* ======================================== HEADER CSS ========================================== */

.header {
  display: flex !important;
  width: 100% !important;
  position: fixed !important;
  top: 0px !important;
  z-index: 999 !important;
}

.header-layout {
  background-color: #fff !important;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(240, 240, 240);
  height: 60px;
  width: 100%;
  padding-left: 2rem;
  padding-right: 1rem;
  justify-content: space-between;
}

.campusplanner-logo {
  position: absolute;
  height: 55px;
  top: 0.15rem;
  left: 1rem;
}

.header-tabs {
  display: flex !important;
  width: 100% !important;
  position: fixed !important;
  top: 4rem !important;
  z-index: 999 !important;
}

.logout-iconButton {
  color: #808080 !important;
  top: 0.9rem;
  width: 2rem !important;
  height: 2rem !important;
  right: var(--1x-spacing) !important;
}

.logout-iconButton:hover {
  background-color: var(--common-color) !important;
  color: white !important;
  cursor: pointer;
  opacity: 60% !important;
}

.logout-icon {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.home-icon {
  width: 1.3rem !important;
  height: 1.8rem !important;
}

.semesterName-text {
  margin-right: 1px !important;
  font-family: var(--font-family) !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 3.8 !important;
}

.semesterName-text span + span {
  margin-left: 8px;
}
